<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">智能驾驶</h1>
        <p data-caption-delay="100" class="wow fadeInRight">针对智能驾驶系统开发全流程的仿真闭环XIL解决方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="智能驾驶MIL/SIL测试系统" name="1">智能驾驶MIL/SIL测试系统</el-tab-pane>
          <el-tab-pane label="智能驾驶HIL测试系统" name="2">智能驾驶HIL测试系统</el-tab-pane>
          <el-tab-pane label="智能驾驶DIL测试系统" name="3">智能驾驶DIL测试系统</el-tab-pane>
          <el-tab-pane label="智能驾驶VIL测试系统" name="4">智能驾驶VIL测试系统</el-tab-pane>
          <el-tab-pane label="AR-HUD XIL测试系统" name="5">AR-HUD XIL测试系统</el-tab-pane>
          <el-tab-pane label="智能车灯XIL测试系统" name="6">智能车灯XIL测试系统</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">
                    智能驾驶MIL（Model in the Loop）/SIL（Software in the Loop）验证系统，是自动驾驶系统/高级驾驶辅助系统处于初期模型或软件开发阶段的常见验证手段。
                    通常以待测算法的形式作为区分，常见的MIL用于验证Simulink的规控算法模型，SIL则用于验证带有感知的ROS环境下的算法。平台主要由仿真软件和工作站资源构建而成。
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
                class="all-function"
                @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
                @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in MSILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <img :src=" item.icon " :style="'width:' + item.width +';padding-top:' + item.padding" alt="">
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components msil-components wow fadeInLeft">
          <div class="container">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-network-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>仿真软件</h1>
                      <ol>
                        <li>场景仿真软件VTD</li>
                        <li>动力学仿真软件DYNA4</li>
                        <li>试验管理软件CANoe</li>
                        <li>自动化测试软件vTESTStudio等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' : 'margin-top: 80px'">
                      <h1>硬件</h1>
                      <ol>
                        <li>高性能图形工作站</li>
                        <li>高性能工作站</li>
                        <li>交换机等</li>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>L1~L5级自动驾驶仿真测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-msil-system-pic-1.jpeg" alt="">
              </div>
              <div class="system-picture" style="margin-top: 20px">
                <img src="../../../assets/images/solution-msil-system-pic-2.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">
                    智能驾驶HIL测试系统是以智能化汽车硬件为测试对象，如智能化控制器或计算平台及智能传感器，搭建与其适配的通用化机柜，通过虚拟仿真场景模拟实车驾驶工况以及待测设备工作环境来对待测设备进行验证和测试的智驾测试系统。
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in HILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <img :src=" item.icon " :style="'width:' + item.width +';padding-top:' + item.padding" alt="">
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components hil-components wow fadeInLeft">
          <div class="container" style="height: 530px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-hil-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>实时平台</h1>
                      <p>NI PXI、Vector VT System等</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 59px'">
                      <h1>场景仿真软件</h1>
                      <p>VTD</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
                      <h1>车辆动力学软件</h1>
                      <p>DYNA4、CarSim等</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
                      <h1>传感器仿真设备</h1>
                      <p>视频注入设备、超声波雷达模拟器、视频暗箱等</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>L1~L5级自动驾驶仿真测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img class="img80" src="../../../assets/images/solution-hil-system-pic.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '3'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">智能驾驶DIL（Driver in the Loop）验证方案，是在HIL平台的基础上，通过驾驶模拟器将真实的驾驶员引入到测试环境，真实驾驶员的参与使得测试变得更加灵活，
                    驾驶行为也与真实情况更为接近，也可验证人机共驾等功能。随着驾驶模拟器内容的逐渐丰富，测试内容和范围也在逐渐增加。东信创智可根据客户的需求，为客户定制不同等级的驾驶模拟器，满足客户的测试需求。</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in DILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <img :src="item.icon" :style="'width:' + item.width +';padding-top:' + item.padding" alt="">
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components dil-components wow fadeInLeft">
          <div class="container" style="height: 400px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-network-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>驾驶模拟器</h1>
                      <ol>
                        <li>智能座舱</li>
                        <li>六自由度运动平台</li>
                        <li>环幕影音系统等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' : 'margin-top: 113px'">
                      <h1>基础HIL测试平台</h1>
                      <ol>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>L1~L5级自动驾驶仿真测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img class="img80" src="../../../assets/images/solution-dil-system-pic.jpg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '4'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">VIL（Vehicle in the Loop）测试系统中同时引入了真实车辆和真实驾驶员，是所有测试系统中最接近实车验证的一步，但由于场景的虚拟化，使得VIL测试相较于实车测试有着更为灵活和安全的测试条件，同时还可以验证车辆在算法控制下的动态响应。</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in VILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <img :src="item.icon" :style="'width:' + item.width +';padding-top:' + item.padding" alt="">
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components vil-components wow fadeInLeft">
          <div class="container" style="height: 560px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-electric-components-pic.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>实时平台</h1>
                      <ol>
                        <li>Vector</li>
                        <li>NI等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 99px'">
                      <h1>传感器仿真设备</h1>
                      <ol>
                        <li>SCMS</li>
                        <li>USSM等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 45px'">
                      <h1>电源系统</h1>
                      <ol>
                        <li>高功率逆变器</li>
                        <li>备用电池等</li>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>L1~L5级自动驾驶仿真测试</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-vil-system-pic-1.jpeg" alt="">
              </div>
              <div class="system-picture" style="margin-top: 20px">
                <img src="../../../assets/images/solution-vil-system-pic-2.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '5'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text" style="width: 100%;">
                    <p>AR-HUD系统相较于传统的HUD系统来讲，投射范围、清晰度、内容数量、功能都更加丰富，并且功能交互大都涉及仪表车机、信息娱乐以及辅助驾驶等核心模块。因此在测试时也不能按照传统的HUD方式来进行测试，而是更加丰富的场景。
                      采用仿真测试的手段可以极大程度的扩展测试边界，丰富的仿真场景库可以提高产品的验证效率、缩短测试周期。因此针对AR-HUD系统的仿真测试，东信创智分别提出SIL/HIL/DIL在环验证方案。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in HUDXILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <i :class=" item.icon "></i>
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components hudxil-components wow fadeInLeft">
          <div class="container" style="height: 500px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-network-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>仿真软件</h1>
                      <ol>
                        <li>场景仿真软件VTD</li>
                        <li>动力学仿真软件DYNA4</li>
                        <li>试验管理软件CANoe</li>
                        <li>自动化测试软件vTESTStudio等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 81px'">
                      <h1>硬件</h1>
                      <ol>
                        <li>高性能图形工作站</li>
                        <li>高性能工作站</li>
                        <li>交换机等</li>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="all-application">
                <div class="application-content">
                  <div class="application-icon">
                    <img src="../../../assets/images/application-picture.svg" alt="">
                  </div>
                  <div class="application-text">
                    <p>AR-HUD仿真测试</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <img class="xil-sys-img" src="../../../assets/images/solution-hudxil-system-pic-1.jpeg" alt="" style="width: 100%; margin-top: 100px">
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <img src="../../../assets/images/solution-hudxil-system-pic-2.jpeg" alt="" style="width: 100%">
                  <img src="../../../assets/images/solution-hudxil-system-pic-3.jpeg" alt="" style="width: 100%">
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '6'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">随着智能驾驶的热潮，为了提升汽车的智能化和安全性，车灯也出现了与智能驾驶相结合的产物ADB（智能远光灯）与DL（数字投影大灯）。
                    基于智能驾驶仿真验证的方案同样可以延伸至智能车灯的仿真验证，因此为了解决结合了智能驾驶功能的智能车灯系统的仿真验证问题，东信创智给出了IDH（智能数字大灯）XIL解决方案。</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div
              class="all-function"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
            >
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in lightXILFunctionList" :key="index">
                  <div class="card-item">
                    <el-card class="solution-function-card" shadow="hover">
                      <div class="card-icon">
                        <img :src=" item.icon " alt="">
                      </div>
                      <div class="card-text">
                        <p>{{ item.content }}</p>
                      </div>
                    </el-card>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components lightXIL-components wow fadeInLeft">
          <div class="container" style="height: 530px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-network-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>仿真软件</h1>
                      <ol>
                        <li>场景仿真软件VTD</li>
                        <li>动力学仿真软件DYNA4</li>
                        <li>试验管理软件CANoe</li>
                        <li>自动化测试软件vTESTStudio等</li>
                      </ol>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 80px'">
                      <h1>硬件</h1>
                      <ol>
                        <li>高性能图形工作站</li>
                        <li>高性能工作站</li>
                        <li>HIL测试台架</li>
                        <li>视频采集卡等</li>
                      </ol>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="all-application">
                <div class="application-content">
                  <div class="application-icon">
                    <img src="../../../assets/images/application-picture.svg" alt="">
                  </div>
                  <div class="application-text">
                    <p>IDH 仿真测试</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-lightXML-system-pic-1.jpeg" alt="">
              </div>
              <div class="system-picture" style="margin-top: 20px">
                <img src="../../../assets/images/solution-lightXML-system-pic-2.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
import MSILFunctionPic1 from '@/assets/images/solution-msil-function-icon-1.svg'
import MSILFunctionPic2 from '@/assets/images/solution-msil-function-icon-2.svg'
import MSILFunctionPic3 from '@/assets/images/solution-msil-function-icon-3.svg'
import MSILFunctionPic4 from '@/assets/images/solution-msil-function-icon-4.svg'
import MSILFunctionPic5 from '@/assets/images/solution-msil-function-icon-5.svg'
import MSILFunctionPic6 from '@/assets/images/solution-msil-function-icon-6.svg'
import HILFunctionPic1 from '@/assets/images/solutions/adas-hil-1.svg'
import HILFunctionPic2 from '@/assets/images/solutions/adas-hil-2.svg'
import HILFunctionPic3 from '@/assets/images/solutions/adas-hil-3.svg'
import HILFunctionPic4 from '@/assets/images/solutions/adas-hil-4.svg'
import HILFunctionPic5 from '@/assets/images/solutions/adas-hil-5.svg'
import HILFunctionPic6 from '@/assets/images/solutions/adas-hil-6.svg'
import HILFunctionPic7 from '@/assets/images/solutions/adas-hil-7.svg'
import DILFunctionPic1 from '@/assets/images/solution-electric-function-icon-1.svg'
import DILFunctionPic2 from '@/assets/images/solution-electric-function-icon-2.svg'
import DILFunctionPic3 from '@/assets/images/solution-electric-function-icon-3.svg'
import DILFunctionPic4 from '@/assets/images/solution-electric-function-icon-4.svg'
export default {
  name: "Drive",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-4',
      tabFixed: false,
      activeProduct: '1',
      MSILFunctionList:[
        {
          icon: MSILFunctionPic1,
          width: "55px",
          padding: "10px",
          content: "纯软环境，支持算法初期的闭环验证"
        },
        {
          icon: MSILFunctionPic2,
          width: "58px",
          padding: "15px",
          content: "支持规控算法及感知算法闭环验证"
        },
        {
          icon: MSILFunctionPic3,
          width: "53px",
          padding: "15px",
          content: "支持自动化测试、加速仿真"
        },
        {
          icon: MSILFunctionPic4,
          width: "55px",
          padding: "10px",
          content: "可升级实现高并发仿真、云仿真"
        },
        {
          icon: MSILFunctionPic5,
          width: "55px",
          padding: "12px",
          content: "支持海量场景测试，积累仿真里程"
        },
        {
          icon: MSILFunctionPic6,
          width: "50px",
          padding: "15px",
          content: "支持apollo、ros、ros2、autoware、Simulink等算法环境"
        },
      ],
      HILFunctionList:[
        {
          icon: HILFunctionPic1,
          width: "68px",
          padding: "0px",
          content: "可对自动驾驶域控制器进行闭环验证"
        },
        {
          icon: HILFunctionPic2,
          width: "72px",
          padding: "5px",
          content: "支持自动化测试"
        },
        {
          icon: HILFunctionPic3,
          width: "63px",
          padding: "5px",
          content: "提供传感器物理模型"
        },
        {
          icon: HILFunctionPic4,
          width: "63px",
          padding: "5px",
          content: "支持传感器信号级注入"
        },
        {
          icon: HILFunctionPic5,
          width: "78px",
          padding: "5px",
          content: "台架支持诊断、标定、刷写、功能验证、功能安全验证以及预期功能安全验证"
        },
        {
          icon: HILFunctionPic6,
          width: "63px",
          padding: "5px",
          content: "支持在线数据记录以及数据回灌"
        },
        {
          icon: HILFunctionPic7,
          width: "80px",
          padding: "0px",
          content: "支持传感器在环测试"
        },
      ],
      DILFunctionList:[
        {
          icon: require('../../../assets/images/solutions/drive-dil-1.svg'),
          width: "75px",
          padding: "0px",
          content: "支持人因分析、人机共驾测试"
        },
        {
          icon: require('../../../assets/images/solutions/drive-dil-2.svg'),
          width: "70px",
          padding: "0px",
          content: "支持定制座舱、实车改装方案"
        },
        {
          icon: require('../../../assets/images/solutions/drive-dil-3.svg'),
          width: "75px",
          padding: "3px",
          content: "多自由度运动平台可选"
        },
        {
          icon: require('../../../assets/images/solutions/drive-dil-4.svg'),
          width: "90px",
          padding: "0px",
          content: "环幕、球幕、联屏等多种视景方案可选"
        },
        {
          icon: require('../../../assets/images/solutions/drive-dil-5.svg'),
          width: "70px",
          padding: "0px",
          content: "真实驾驶员接入"
        },
      ],
      VILFunctionList:[
        {
          icon: require('../../../assets/images/solutions/drive-vil-1.svg'),
          width: "70px",
          padding: "0px",
          content: "实车仿真验证"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-2.svg'),
          width: "75px",
          padding: "0px",
          content: "外场及实验室内验证方案可选"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-3.svg'),
          width: "78px",
          padding: "0px",
          content: "改装量小"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-4.svg'),
          width: "65px",
          padding: "7px",
          content: "轻量级HIL系统"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-5.svg'),
          width: "65px",
          padding: "10px",
          content: "支持传感器在环测试（实验室专用）"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-6.svg'),
          width: "65px",
          padding: "0px",
          content: "车载供电系统（场地版专用）"
        },
        {
          icon: require('../../../assets/images/solutions/drive-vil-7.svg'),
          width: "72px",
          padding: "7px",
          content: "车载高精定位同步系统（场地版专用）"
        },
      ],
      HUDXILFunctionList:[
        {
          icon: "ri-roadster-fill",
          content: "XIL在环解决方案"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持自动化测试"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持在线数据记录、视频流记录"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持传感器在环测试"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持导航模拟"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持自动驾驶功能模拟"
        },
        {
          icon: "ri-roadster-fill",
          content: "支持人因工程分析及座舱定制"
        },
      ],
      lightXILFunctionList:[
        {
          icon: DILFunctionPic1,
          content: "XIL在环解决方案"
        },
        {
          icon: DILFunctionPic2,
          content: "支持自动化测试"
        },
        {
          icon: DILFunctionPic3,
          content: "支持在线数据记录、视频流记录"
        },
        {
          icon: DILFunctionPic4,
          content: "支持传感器在环测试"
        },
        {
          icon: DILFunctionPic1,
          content: "支持ADB验证"
        },
        {
          icon: DILFunctionPic2,
          content: "支持流媒体投影等验证"
        },
        {
          icon: DILFunctionPic2,
          content: "支持投影标定验证"
        },
        {
          icon: DILFunctionPic3,
          content: "支持IES光形导入"
        },
        {
          icon: DILFunctionPic4,
          content: "支持人因工程分析及座舱定制"
        },
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "智能驾驶 - 解决方案 - 东信创智";
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    }
  }
}
</script>

<style scoped>

</style>
